<template>
  <div>
    <div>
      <van-cell-group>
        <!-- <div style="border-top:1px dashed #ac9090;padding:10px;">4、证件信息</div> -->
        <van-notice-bar left-icon="volume-o"
          v-if="noticeShow"
          scrollable
          wrapable
          :text="dataform.Reason" />
        <van-field v-model="dataform.organname"
          label="社区/村庄"
          placeholder="请选择所属社区/村庄"
          @click="orgCodePicker = true"
          readonly="readonly"
          required
          clickable
          v-if="orgCodeShow" />
        <van-popup v-model="orgCodePicker"
          position="bottom">
          <van-picker show-toolbar
            :columns="orgList"
            value-key="organname"
            @confirm="orgCodeQr"
            @cancel="orgCodePicker = false" />
        </van-popup>
        <van-field v-model="dataform.Name"
          required
          center
          label="老人姓名"
          placeholder="请输入老人姓名"
          :rules="[{ required: true, message: '请输入老人姓名' }]">
          <template #button>
            <van-uploader :after-read="afterReadBaidu">
              <van-icon name="scan"
                size="35" />
            </van-uploader>
          </template>
        </van-field>
        <van-field v-model="dataform.IDCard"
          required
          label="身份证号"
          placeholder="请输入身份证号"
          @blur="IdNum(dataform.IDCard)"
          :rules="[{ required: true, message: '请输入身份证号' }]" />

        <van-field name="Sex"
          required
          label="性别">
          <template #input>
            <van-radio-group v-model="dataform.Sex"
              direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="dataform.Birthday"
          required
          name="RelieveTime"
          label="出生日期"
          @click="birthdayData = true"
          readonly="readonly"
          placeholder="请选择出生日期" />
        <van-popup v-model="birthdayData"
          position="bottom"
          get-container="body">
          <van-datetime-picker show-toolbar
            type="date"
            v-model="birthdayDataNy"
            title="请选择出生日期"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="birthdayData = false"
            @confirm="onbirthdayData">
          </van-datetime-picker>
        </van-popup>
        <!-- <van-field v-model="dataform.Nation"
          required
          label="民族"
          placeholder="请输入民族" /> -->
        <van-field v-model="dataform.NationName"
          label="民族"
          required
          placeholder="请选择所属民族"
          @click="minzuPicker = true"
          readonly="readonly"
          clickable />
        <van-popup v-model="minzuPicker"
          position="bottom">
          <van-picker show-toolbar
            :columns="minzu"
            value-key="Name"
            @confirm="onrecumenANation"
            @cancel="minzuPicker = false" />
        </van-popup>
        <van-field v-model="dataform.RegAddr"
          required
          label="户籍地址"
          type="textarea"
          rows="1"
          autosize
          placeholder="请输入户籍地址" />
        <van-field v-model="dataform.Address"
          required
          label="居住地址"
          type="textarea"
          rows="1"
          autosize
          placeholder="请输入居住地址">
          <template #button>
            <van-button size="mini"
              type="primary"
              @click="writeAddress()">同步户籍地址</van-button>
          </template>
        </van-field>
        <van-field v-model="dataform.Tel"
          required
          label="老人电话"
          placeholder="请输入老人电话" />
        <van-field v-model="dataform.TouchMan"
          required
          label="联系人姓名"
          placeholder="请输入联系人姓名" />
        <van-field v-model="dataform.TouchTel"
          required
          label="联系人电话"
          placeholder="请输入联系人电话" />

        <van-field name="Marriage"
          label="婚姻状况">
          <template #input>
            <van-radio-group v-model="dataform.Marriage"
              direction="horizontal">
              <van-radio name="2"
                style="margin: 5px 5px 5px 0">已婚</van-radio>
              <van-radio name="1"
                style="margin: 5px 5px 5px 0">未婚</van-radio>
              <van-radio name="3"
                style="margin: 5px 5px 5px 0">丧偶</van-radio>
              <van-radio name="4"
                style="margin: 5px 5px 5px 0">离婚</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="BodyState"
          label="身体状况">
          <template #input>
            <van-radio-group v-model="dataform.BodyState"
              direction="horizontal">
              <van-radio name="1"
                style="margin:5px 5px 5px 0">自理</van-radio>
              <van-radio name="2"
                style="margin:5px 5px 5px 0">半自理</van-radio>
              <van-radio name="3"
                style="margin:5px 5px 5px 0">失能</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="dataform.LiveStateDesc"
          label="居住状况"
          placeholder="请选择居住状况"
          @click="juzhuPicker = true"
          readonly="readonly"
          clickable
          required />
        <van-popup v-model="juzhuPicker"
          position="bottom">
          <van-picker show-toolbar
            :columns="juzhuList"
            value-key="label"
            @confirm="juzhuQr"
            @cancel="juzhuPicker = false" />
        </van-popup>
        <van-field v-model="dataform.PlanBirFamilyDesc"
          label="计划生育"
          placeholder="请选择计划生育特殊家庭"
          @click="shengyuPicker = true"
          readonly="readonly"
          clickable />
        <van-popup v-model="shengyuPicker"
          position="bottom">
          <van-picker show-toolbar
            :columns="shengyuList"
            value-key="label"
            @confirm="shengyuQr"
            @cancel="shengyuPicker = false" />
        </van-popup>
        <van-field name="Lonely"
          label="三无老人">
          <template #input>
            <van-radio-group v-model="dataform.Lonely"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="PoorMan"
          label="低保老人">
          <template #input>
            <van-radio-group v-model="dataform.PoorMan"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="ExtremelyPoor"
          label="特困老人">
          <template #input>
            <van-radio-group v-model="dataform.ExtremelyPoor"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="Paralysis"
          label="经济困难失能半失能老人">
          <template #input>
            <van-radio-group v-model="dataform.Paralysis"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="StayBehind"
          label="农村留守老人">
          <template #input>
            <van-radio-group v-model="dataform.StayBehind"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="EmptyNest"
          label="城市空巢老人">
          <template #input>
            <van-radio-group v-model="dataform.EmptyNest"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="AdvancedAge"
          label="高龄老人">
          <template #input>
            <van-radio-group v-model="dataform.AdvancedAge"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="Deformity"
          label="残疾老人">
          <template #input>
            <van-radio-group v-model="dataform.Deformity"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="OtherMan"
          label="其他老人类型">
          <template #input>
            <van-radio-group v-model="dataform.OtherMan"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="dataform.Diseases"
          label="基础疾病"
          placeholder="请选择基础疾病"
          @click="DiseasesShow = true"
          readonly="readonly"
          clickable />
        <van-popup v-model="DiseasesShow"
          position="bottom">
          <van-picker show-toolbar
            :columns="jibingList"
            value-key="Name"
            @confirm="jibingQr"
            @cancel="DiseasesShow = false" />
        </van-popup>
        <van-field name="Demand"
          label="养老需求">
          <template #input>
            <van-checkbox-group v-model="dataform.Demand"
              direction="horizontal">
              <van-checkbox name="助餐"
                shape="square"
                style="margin: 5px">助餐</van-checkbox>
              <van-checkbox name="助洁"
                shape="square"
                style="margin: 5px">助洁</van-checkbox>
              <van-checkbox name="助行"
                shape="square"
                style="margin: 5px">助行</van-checkbox>
              <van-checkbox name="助浴"
                shape="square"
                style="margin: 5px">助浴</van-checkbox>
              <van-checkbox name="助医"
                shape="square"
                style="margin: 5px">助医</van-checkbox>
              <van-checkbox name="康复"
                shape="square"
                style="margin: 5px">康复</van-checkbox>
              <van-checkbox name="巡访关爱"
                shape="square"
                style="margin: 5px">巡访关爱</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>

        <div class="submit"
          v-if="shReason">
          <span style="color: red; font-size: 14px;letter-spacing:8px">*</span>身份证照、生活照、(如有残疾、低保、特困类证件照)照片上传
          <span style="color: red;font-size: 12px;">! 照片大小不得超过6MB</span>
        </div>
        <van-uploader v-model="fileList"
          :multiple="true"
          class="imgs"
          :max-count="9"
          :after-read="afterRead"
          :before-delete="deleteImgs"
          :deletable="true"
          :max-size="6000 * 1024"
          v-if="shReason"
          @oversize="onOversize" />
        <div v-if="imgShow">
          <div style="font-size: 12px; margin: 5px 10px">
            已上传照片(<span style="color: red">如果新增照片,需将已上传照片重新上传</span>)
          </div>
          <div v-for="item in imgArr"
            :key="item.index"
            style="
              display: inline-block;
              margin: 0px 10px;
              width: 80px;
              height: 80px;
            ">
            <img style="width: 100%; height: 100%"
              :src="item"
              @click="imgClick(dataform.HeadImgUrl)" />
          </div>
        </div>
        <!-- <div class="submit">残疾、低保、特困类证件照（选填项）( <span style="color:red">照片大小不得超过6MB</span> )</div>
        <van-uploader
          v-model="fileListPoor"
          multiple
          class="imgs"
          :max-count="9"
          :after-read="afterReadPoor"
          :before-delete="deletePoor"
          :deletable="true"
          :max-size="6000 * 1024"
          @oversize="onOversize"
        /> -->
        <van-field v-model="dataform.Remark"
          label="备注信息"
          type="textarea"
          rows="1"
          autosize
          placeholder="请输入备注信息" />
        <van-button round
          block
          type="info"
          color="#617bfa"
          style="font-size: 16px; margin: 10px auto; width: 90%"
          v-if="saveShow"
          :loading="saveLoading"
          loading-text="保存中..."
          @click="SaveZh()">保存</van-button>

      </van-cell-group>
    </div>
  </div>
</template>
<script>
import Compressor from "compressorjs";

import {
  WeGetOldManPage,
  DeleteOldMan,
  WeSaveOldMan,
  GetChildOrgansNullToken,
  GetCodeEntrysList,
} from "@/api/oldman";
import { getoldmanToken, setoldmanToken } from "@/utils/auth";
import { WeGetOrganTree } from "@/api/Organ";
import config from "@/config";
import axios from "axios";
import Vue from "vue";
import { ImagePreview } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
Vue.use(ImagePreview);
export default {
  data () {
    return {
      listdata: {
        accToken: getoldmanToken(), //token
        page: 1,
        limit: 100,
      },
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2050, 10, 1),
      birthdayDataNy: new Date(2021, 0, 17),
      listList: {},
      cascaderValue: "",
      count: "",
      orgonList: [],
      // 登记
      dataform: {
        OMId: 0,
        Name: "",
        SourceFlag: 2,
        Nation: "",
        Birthday: "",
        IDCard: "",
        Sex: "",
        RegAddr: "",
        Address: "",
        HeadImg: "",
        Marriage: "2",
        BodyState: "1",
        LiveState: "",
        Lonely: "0",
        PlanBirFamily: "",
        PoorMan: "0",
        ExtremelyPoor: "0",
        Paralysis: "0",
        StayBehind: "0",
        EmptyNest: "0",
        AdvancedAge: "0",
        Deformity: "0",
        OtherMan: "0",
        Diseases: "",
        Demand: [],
        Tel: "",
        TouchMan: "",
        TouchTel: "",
        AuditState: 1,
        Remark: "",
        PoorImg: "",
        accToken: "",
      },
      minzu: "",
      djshow: "1",
      orgCodeShow: true,//所属社区村庄
      birthdayData: false, //出生日期
      juzhuPicker: false, //疾病状况
      shengyuPicker: false, //计划生育特殊家庭
      orgCodePicker: false, //社区街道
      minzuPicker: false, //民族
      DiseasesShow: false, //疾病
      shReason: false,
      noticeShow: false,
      saveShow: false,
      saveLoading: false,
      juzhuList: [
        { value: "1", label: "独居" },
        { value: "2", label: "与配偶、伴侣居住" },
        { value: "3", label: "与子女居住" },
        { value: "4", label: "与父母居住" },
        { value: "5", label: "与其他亲属居住" },
        { value: "6", label: "与非亲属关系人居住" },
        { value: "7", label: "养老机构居住" },
      ],
      shengyuList: [
        { value: "1", label: "子女死亡" },
        { value: "2", label: "子女伤残" },
        { value: "3", label: "手术并发症3级" },
      ],
      uploadImgs: [],
      uploadImgsPoor: [],
      fileList: [],
      fileListPoor: [],
      orgList: [],
      jibingList: [
        { id: 1, Name: "高血压" },
        { id: 2, Name: "糖尿病" },
        { id: 3, Name: "冠心病" },
        { id: 4, Name: "慢性阻塞性肺疾病" },
        { id: 5, Name: "恶性肿瘤" },
        { id: 6, Name: "脑卒中" },
        { id: 7, Name: "脑血管疾病（含中风）" },
        { id: 8, Name: "重性精神疾病" },
        { id: 9, Name: "结核病" },
        { id: 10, Name: "肺炎" },
        { id: 11, Name: "其他法定传染病" },
        { id: 12, Name: "阿尔兹海默症" },
        { id: 13, Name: "职业病" },
        { id: 14, Name: "其他" },
      ],
      access_token: "",
      imgShow: false,
    };
  },
  created () {
    this.zidianlist();
    // this.baiduToken()
    if (this.$route.params.id != 0 && this.$route.params.id != undefined) {
      this.getDetail();
      this.orgCodeShow = false
    }
    this.getOrgonTree();
  },
  methods: {
    getDetail () {
      console.log(this.$route.params.id);
      this.dataform = JSON.parse(this.$route.params.id);
      console.log(this.dataform);
      WeGetOldManPage(this.dataform).then((res) => {
        if (res.data.code == 0) {
          this.dataform = res.data.data;
          if (this.dataform.AuditState == 1) {
            Toast.fail('信息待审核');
            this.shReason = true
            this.saveShow = true
            this.noticeShow = false
          } else if (this.dataform.AuditState == 2) {
            Toast.fail('信息已审核');
            this.shReason = false
            this.noticeShow = false
          } else if (this.dataform.AuditState == 9) {
            Toast.fail('信息已审核未通过');
            this.shReason = true
            this.saveShow = true
            this.noticeShow = true
          }
          this.dataform.Sex = this.dataform.Sex + "";
          this.dataform.Marriage = this.dataform.Marriage + "";
          this.dataform.BodyState = this.dataform.BodyState + "";
          this.dataform.Lonely = this.dataform.Lonely + "";
          this.dataform.PoorMan = this.dataform.PoorMan + "";
          this.dataform.ExtremelyPoor = this.dataform.ExtremelyPoor + "";
          this.dataform.Paralysis = this.dataform.Paralysis + "";
          this.dataform.StayBehind = this.dataform.StayBehind + "";
          this.dataform.EmptyNest = this.dataform.EmptyNest + "";
          this.dataform.AdvancedAge = this.dataform.AdvancedAge + "";
          this.dataform.Deformity = this.dataform.Deformity + "";
          this.dataform.OtherMan = this.dataform.OtherMan + "";
          this.dataform.Demand = this.dataform.Demand.split(",");
          // 时间转格式
          this.dataform.Birthday = this.dataform.Birthday.slice(0, 10);
          // 图片转格式
          this.imgArr = this.dataform.HeadImgUrl.split(",");
          this.imgShow = true;
          if (this.dataform.PoorImgUrl != "") {
            this.imgsArr = this.dataform.PoorImgUrl.split(",");
            this.imgsShow = true;
          }
        } else if (res.data.code == 1) {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 点击预览图片
    imgClick (row) {
      var imgArr = row.split(",");
      ImagePreview(imgArr);
    },
    onOversize () {
      Toast("文件大小不能超过 6M");
    },
    // 同步户籍地址
    writeAddress () {
      if (this.dataform.RegAddr != "") {
        this.dataform.Address = this.dataform.RegAddr;
      } else {
        Toast.fail("请输入户籍地址!");
      }
    },
    baiduToken () {
      axios({
        method: "post",
        url:
          this.$host +
          "/oauth/2.0/token?grant_type=client_credentials&client_id=l75tR92b7eirQA3BPD0YBTLP&client_secret=9Z88glkBuIxD7LQz4xQY6KzK9znc027Z",
        // url: '//aip.baidubce.com/oauth/2.0/token?grant_type=client_credentials&client_id=l75tR92b7eirQA3BPD0YBTLP&client_secret=9Z88glkBuIxD7LQz4xQY6KzK9znc027Z',
        // data: filterData,
      }).then((res) => {
        console.log(res.data.access_token);
        this.access_token = res.data.access_token;
      });
    },
    // 字典查询
    zidianlist: function () {
      // 民族
      GetCodeEntrysList({ kind: "2" }).then((res) => {
        this.minzu = res.data.data;
      });
    },
    onrecumenANation (val) {
      //民族
      this.dataform.Nation = val.Coding;
      this.dataform.NationName = val.Name;
      this.minzuPicker = false;
    },
    // 地区选择完成后 触发事件
    onFinish ({ selectedOptions }) {
      this.sqshow = false;
      this.fieldValue = selectedOptions.map((option) => option.OName).join("/");
      this.OCode = selectedOptions.map((option) => option.OCode).join("/");
      this.dataform.OrganName = this.fieldValue;
      this.dataform.OrganCode = selectedOptions[1].OCode;
    },
    getOrgonTree () {
      // WeGetOrganTree().then((res) => {
      //   this.orgonList = res.data.data;
      // });
      if (this.$route.params.OrganId != undefined) {
        this.orgCodeShow = true
        this.saveShow = true
        this.shReason = true
        this.noticeShow = false
        GetChildOrgansNullToken({ organid: this.$route.params.OrganId }).then(
          (res) => {

            if (res.data.code == 0) {
              this.orgList = res.data.data;
            } else {
              Toast.fail(res.data.msg);
            }
          }
        );
      } else {
        this.orgCodeShow = false
      }
    },

    //出生日期
    onbirthdayData (val) {
      this.dataform.Birthday = this.dateformat(val);
      this.birthdayData = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    jibingQr (val) {
      //疾病情况
      this.dataform.Diseases = val.Name;
      this.DiseasesShow = false;
    },
    juzhuQr (val) {
      //居住情况
      this.dataform.LiveStateDesc = val.label;
      this.dataform.LiveState = val.value;
      this.juzhuPicker = false;
    },
    shengyuQr (val) {
      //生育情况
      this.dataform.PlanBirFamilyDesc = val.label;
      this.dataform.PlanBirFamily = val.value;
      this.shengyuPicker = false;
    },
    orgCodeQr (val) {
      //社区街道
      this.dataform.organname = val.organname;
      this.dataform.OrgCode = val.organcode;
      this.orgCodePicker = false;
    },
    deleteImgs () {
      this.fileList = [];
      this.uploadImgs = [];
      this.dataform.HeadImg = [];
    },
    deletePoor () {
      this.fileListPoor = [];
      this.uploadImgsPoor = [];
      this.dataform.PoorImg = [];
    },
    // 身份证识别
    afterReadBaidu (file, fileList) {
      var that = this;
      if (file.file.size > 1500000) {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在识别...',
          forbidClick: true,
        });
        new Compressor(file.file, {

          quality: 0.6,
          success (result) {
            var formData = new FormData();
            formData.append("file", result, result.name);
            formData.append("access_token", "24.94ffa59d62dd6ee6d3ac23213e59df98.2592000.1637914886.282335-24647933"
            );
            formData.append("image", file.content.split(",")[1]);
            formData.append("id_card_side", "front");
            let filterData = {
              access_token: that.access_token,
              image: file.content.split(",")[1],
              id_card_side: "front",
            };

            axios({
              url: "https://aip.baidubce.com/rest/2.0/ocr/v1/idcard",
              method: "post",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: formData,
            }).then((res) => {
              if (res.data.idcard_number_type == 1) {
                Toast.clear();
                Toast.success("识别成功");
                that.dataform.Name = res.data.words_result["姓名"].words;
                that.dataform.IDCard = res.data.words_result["公民身份号码"].words;
                that.dataform.RegAddr = res.data.words_result["住址"].words;
                that.IdNum(res.data.words_result["公民身份号码"].words);
              } else {
                Toast.clear();
                Toast.fail("识别失败");
              }
            }).catch((ce) => {
              console.log(ce);
            });
          }
        })
      } else {
        var fileData = new FormData();
        fileData.append("access_token", "24.94ffa59d62dd6ee6d3ac23213e59df98.2592000.1637914886.282335-24647933");
        fileData.append("image", file.content.split(",")[1]);
        fileData.append("id_card_side", "front");
        let filterData = {
          access_token: that.access_token,
          image: file.content.split(",")[1],
          id_card_side: "front",
        };
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在识别...',
          forbidClick: true,
        });
        axios({
          url: "https://aip.baidubce.com/rest/2.0/ocr/v1/idcard",
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: fileData,
        }).then((res) => {
          if (res.data.idcard_number_type == 1) {

            Toast.success("识别成功");
            Toast.clear();
            that.dataform.Name = res.data.words_result["姓名"].words;
            that.dataform.IDCard = res.data.words_result["公民身份号码"].words;
            that.dataform.RegAddr = res.data.words_result["住址"].words;
            that.IdNum(res.data.words_result["公民身份号码"].words);
          } else {
            Toast.clear();
            Toast.fail("识别失败");
          }
        }).catch((ce) => {
          console.log(ce);
          Toast.success(ce);
          Toast.success(res.data);
          console.log(res.data);
        });
      }
    },
    async fileImgCompressor (files) {
      files.forEach(
        function (file, i) {
          // if (file.size > 400000) {
          return new Promise(function (resolve, reject) {
            new Compressor(file.file, {
              quality: 0.5,
              success (result) {
                files[i] = result;
                console.log(result);
                resolve(result);
              },
            });
          });
          // }
        }
      );
      return files;
    },
    // 证件照
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      // 大于2.5MB的jpeg和png图片都缩小像素上传
      if (file.file.size > 2500000) {
        new Compressor(file.file, {
          quality: 0.6,
          success (result) {
            var formData = new FormData();
            let c = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            //构造一个 FormData，把后台需要发送的参数添加
            formData.append("file", result, result.name);
            axios
              .post(config.apiUploadUrl, formData, c)
              .then((res) => {
                if (res.data.code === 0) {
                  that.uploadImgs = that.uploadImgs.concat(res.data.data);
                  that.dataform.HeadImg = that.uploadImgs;
                  file.status = "";
                  file.message = "";
                } else {
                  Toast.fail(res.data.msg);
                }
              })
              .catch(() => {
                Toast.fail("图片上传失败");
                file.status = "failed";
                file.message = "上传失败";
              });
          },
        });
      } else {
        var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
        if (Array.isArray(file)) {
          file.forEach((item, index) => {
            formData.append("file", item.file);
          });
        } else {
          formData.append("file", file.file);
        }
        let c = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        // 添加请求头
        axios
          .post(config.apiUploadUrl, formData, c)
          .then((res) => {
            if (res.data.code === 0) {
              this.uploadImgs = this.uploadImgs.concat(res.data.data);
              this.dataform.HeadImg = this.uploadImgs;
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          })
          .catch(() => {
            Toast.fail("图片上传失败");
            file.status = "failed";
            file.message = "上传失败";
          });
      }
    },
    afterReadce (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      console.log(file.file.size);
      if ('1000000' < file.file.size && file.file.size < '1500000') {
        new Compressor(file.file, {
          quality: 0.7,
          success (result) {
            console.log(result);
          }
        });
      } else if ('1500000' < file.file.size && file.file.size < '2000000') {
        new Compressor(file.file, {
          quality: 0.65,
          success (result) {
            console.log(result);
          }
        });
      } else if ('2000000' < file.file.size && file.file.size < '2500000') {
        new Compressor(file.file, {
          quality: 0.6,
          success (result) {
            console.log(result);
          }
        });
      } else if ('2500000' < file.file.size && file.file.size < '3000000') {
        new Compressor(file.file, {
          quality: 0.55,
          success (result) {
            console.log(result);
          }
        });
      } else if ('3000000' < file.file.size && file.file.size < '3500000') {
        new Compressor(file.file, {
          quality: 0.5,
          success (result) {
            console.log(result);
          }
        });
      } else if ('3500000' < file.file.size && file.file.size < '4000000') {
        new Compressor(file.file, {
          quality: 0.45,
          success (result) {
            console.log(result);
          }
        });
      } else if ('4000000' < file.file.size && file.file.size < '4500000') {
        new Compressor(file.file, {
          quality: 0.4,
          success (result) {
            console.log(result);
          }
        });
      } else if (file.file.size > '4500000') {
        new Compressor(file.file, {
          quality: 0.2,
          success (result) {
            console.log(result);
          }
        });
      }
    },
    // 残疾、低保、特困类证件照
    afterReadPoor (file) {
      console.log(file);
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      // 大于2.5MB的jpeg和png图片都缩小像素上传
      if (file.file.size > 3500000) {
        new Compressor(file.file, {
          quality: 0.5,
          success (result) {
            var formData = new FormData();
            let c = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            //构造一个 FormData，把后台需要发送的参数添加
            formData.append("file", result, result.name);
            axios
              .post(config.apiUploadUrl, formData, c)
              .then((res) => {
                if (res.data.code === 0) {
                  this.uploadImgs = this.uploadImgsPoor.concat(res.data.data);
                  this.dataform.PoorImg = this.uploadImgs;
                  file.status = "";
                  file.message = "";
                } else {
                  Toast.fail(res.data.msg);
                }
              })
              .catch(() => {
                Toast.fail("图片上传失败");
                file.status = "failed";
                file.message = "上传失败";
              });
          },
        });
      } else {
        var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
        if (Array.isArray(file)) {
          file.forEach((item, index) => {
            formData.append("file", item.file);
          });
        } else {
          formData.append("file", file.file);
        }
        let c = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        // 添加请求头
        axios
          .post(config.apiUploadUrl, formData, c)
          .then((res) => {
            if (res.data.code === 0) {
              this.uploadImgs = this.uploadImgsPoor.concat(res.data.data);
              this.dataform.PoorImg = this.uploadImgs;
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          })
          .catch(() => {
            Toast.fail("图片上传失败");
            file.status = "failed";
            file.message = "上传失败";
          });
      }
    },
    // 获取身份证
    IdNum (value) {
      console.log(value);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (value.length > 0) {
        if (reg.test(value)) {
          this.go(value.length);
        } else {
          Toast.fail("身份证号码不正确");
        }
      }
    },
    go (val) {
      var iden =
        this.dataform.IDCard == ""
          ? this.dataform.IDCard
          : this.dataform.IDCard;
      console.log(iden);
      var id = iden.substring(0, 6);
      this.dataform.NativeCantonCode = id;
      console.log(this.dataform.NativeCantonCode);
      var sex = null;
      var birth = null;
      var myDate = new Date();
      var month = myDate.getMonth() + 1;
      var day = myDate.getDate();
      var age = 0;
      if (val === 18) {
        age = myDate.getFullYear() - iden.substring(6, 10) - 1;
        sex = iden.substring(16, 17);
        birth =
          iden.substring(6, 10) +
          "-" +
          iden.substring(10, 12) +
          "-" +
          iden.substring(12, 14);
        if (
          iden.substring(10, 12) < month ||
          (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)
        )
          age++;
      }
      if (val === 15) {
        age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
        sex = iden.substring(13, 14);
        birth =
          "19" +
          iden.substring(6, 8) +
          "-" +
          iden.substring(8, 10) +
          "-" +
          iden.substring(10, 12);
        if (
          iden.substring(8, 10) < month ||
          (iden.substring(8, 10) == month && iden.substring(10, 12) <= day)
        )
          age++;
      }
      if (sex % 2 == 0) {
        sex = 2;
        this.dataform.Sex = sex + "";
        // this.dataform.Sex = sex;
        console.log(this.dataform.Sex);
        this.age = age;
        this.dataform.Birthday = birth;
        this.birthdayDataNy = new Date(this.dataform.Birthday);
      } else {
        sex = 1;
        // this.dataform.Sex = sex;
        this.dataform.Sex = sex + "";
        console.log(this.dataform.Sex);
        this.age = age;
        this.dataform.Birthday = birth;
        this.birthdayDataNy = new Date(this.dataform.Birthday);
      }
    },
    SaveZh () {
      var regPos = /^[\u4e00-\u9fa5]{0,}$/;
      var idcode = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (
        this.dataform.OrgCode == "" ||
        this.dataform.OrgCode == null ||
        this.dataform.OrgCode == undefined
      ) {
        Toast.fail("请选择所属社区/村庄");
      } else if (
        this.dataform.Name == "" ||
        this.dataform.Name == null ||
        this.dataform.Name == undefined
      ) {
        Toast.fail("请输入老人姓名");
      } else if (
        regPos.test(this.dataform.Name) != true
      ) {
        Toast.fail("请输入正确的老人姓名");
      } else if (
        idcode.test(this.dataform.IDCard) != true
      ) {
        Toast.fail("请选择正确身份证号");
      } else if (
        this.dataform.IDCard == "" ||
        this.dataform.IDCard == null ||
        this.dataform.IDCard == undefined
      ) {
        Toast.fail("请输入身份证号");
      } else if (
        this.dataform.Birthday == "" ||
        this.dataform.Birthday == null ||
        this.dataform.Birthday == undefined
      ) {
        Toast.fail("请选择出生日期");
      } else if (
        this.dataform.Sex == "" ||
        this.dataform.Sex == null ||
        this.dataform.Sex == undefined
      ) {
        Toast.fail("请选择性别");
      } else if (
        this.dataform.Nation == "" ||
        this.dataform.Nation == null ||
        this.dataform.Nation == undefined
      ) {
        Toast.fail("请选择民族");
      } else if (
        this.dataform.RegAddr == "" ||
        this.dataform.RegAddr == null ||
        this.dataform.RegAddr == undefined
      ) {
        Toast.fail("请输入户籍地址");
      } else if (
        this.dataform.Address == "" ||
        this.dataform.Address == null ||
        this.dataform.Address == undefined
      ) {
        Toast.fail("请输入居住地址");
      } else if (
        this.dataform.Tel == "" ||
        this.dataform.Tel == null ||
        this.dataform.Tel == undefined
      ) {
        Toast.fail("请输入老人电话");
      } else if (
        this.dataform.TouchMan == "" ||
        this.dataform.TouchMan == null ||
        this.dataform.TouchMan == undefined
      ) {
        Toast.fail("请输入联系人姓名");
      } else if (
        regPos.test(this.dataform.TouchMan) != true
      ) {
        Toast.fail("请输入正确的联系人姓名");
      } else if (
        this.dataform.TouchTel == "" ||
        this.dataform.TouchTel == null ||
        this.dataform.TouchTel == undefined
      ) {
        Toast.fail("请输入联系人电话");
      } else if (
        this.dataform.LiveState == "" ||
        this.dataform.LiveState == null ||
        this.dataform.LiveState == undefined
      ) {
        Toast.fail("请选择居住状况");
      } else if (
        this.dataform.HeadImg == "" ||
        this.dataform.HeadImg == null ||
        this.dataform.HeadImg == undefined
      ) {
        Toast.fail("请上传证件照");
      } else {
        this.dataform.Sex = Number(this.dataform.Sex);
        this.dataform.Marriage = Number(this.dataform.Marriage);
        this.dataform.BodyState = Number(this.dataform.BodyState);
        this.dataform.LiveState = Number(this.dataform.LiveState);
        this.dataform.Lonely = Number(this.dataform.Lonely);
        this.dataform.PlanBirFamily = Number(this.dataform.PlanBirFamily);
        this.dataform.PoorMan = Number(this.dataform.PoorMan);
        this.dataform.ExtremelyPoor = Number(this.dataform.ExtremelyPoor);
        this.dataform.Paralysis = Number(this.dataform.Paralysis);
        this.dataform.StayBehind = Number(this.dataform.StayBehind);
        this.dataform.EmptyNest = Number(this.dataform.EmptyNest);
        this.dataform.AdvancedAge = Number(this.dataform.AdvancedAge);
        this.dataform.Deformity = Number(this.dataform.Deformity);
        this.dataform.OtherMan = Number(this.dataform.OtherMan);
        this.dataform.Demand = this.dataform.Demand + "";
        this.dataform.HeadImg = this.dataform.HeadImg + "";
        this.dataform.PoorImg = this.dataform.PoorImg + "";
        this.dataform.OpenId = "";
        this.saveLoading = true,
          WeSaveOldMan(this.dataform).then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              Toast.success("保存成功!");
              this.saveLoading = false,
                this.$router.replace({ name: "shenbaoShouYe" });
            } else {
              Toast.fail(res.data.msg);
              this.saveLoading = false,
                (this.fileList = []),
                (this.fileListPoor = []),
                (this.uploadImgs = []),
                (this.uploadImgsPoor = []),
                (this.dataform = {
                  Name: "",
                  SourceFlag: 2,
                  Nation: "",
                  Birthday: "",
                  IDCard: "",
                  Sex: "",
                  RegAddr: "",
                  Address: "",
                  HeadImg: "",
                  Marriage: "2",
                  BodyState: "1",
                  LiveState: "",
                  Lonely: "2",
                  PlanBirFamily: "",
                  PoorMan: "2",
                  ExtremelyPoor: "2",
                  Paralysis: "2",
                  Diseases: "",
                  Demand: [],
                  Tel: "",
                  TouchMan: "",
                  TouchTel: "",
                  Remark: "",
                  PoorImg: "",
                  StayBehind: "2",
                  EmptyNest: "2",
                  AdvancedAge: "2",
                  Deformity: "2",
                  OtherMan: "2",
                });
            }
          }).catch((_) => {
            setTimeout(() => {
              this.saveLoading = false;
            }, 1000);
          });

      }
    },
  },
};
</script>
<style scoped>
.title-class {
  min-width: 30% !important;
}
.value-class {
  min-width: 50% !important;
}
.submit {
  position: relative;
  font-size: 17px;
  color: #646566;
  line-height: 24px;
  padding: 0 30px 20px 15px;
}
.imgs {
  margin: 10px;
}
.van-cell {
  font-size: 17px;
}
</style>